import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      height: '700px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '700px',
      width: 'fit-content',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '16px',
      marginBottom: '48px',
    },
    widgetContentBlock: {
      height: '700px',
      '& .persona-widget__iframe': {
        minHeight: '700px',
        minWidth: '450px',
        borderRadius: '0px 0px 10px 10px',
      },
    },
    content: {
      height: '700px',
    },
  });
}
