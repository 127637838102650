import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { FormControl, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { PhoneConverter } from '@shared/utils/phone-converter';
import { UseFormRegisterReturn } from 'react-hook-form';

import { StyledFormHelperText, styles } from './input-phone.styles';

export type InputPhoneFieldProps = AppWithStyles<any> &
  TextFieldProps & {
    resetValidation?: () => void;
    children?: React.ReactNode;
    controls: UseFormRegisterReturn;
    defaultValue?: string;
    onChangeValue?: () => void;
  };

const InputPhoneComponent: React.FC<InputPhoneFieldProps> = ({
  classes,
  controls,
  resetValidation,
  helperText,
  error,
  autoFocus,
  maxRows,
  defaultValue,
  onChangeValue,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(defaultValue || '');

  const phoneConverter = useMemo(() => new PhoneConverter(), []);

  const phoneNumberInputRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      onChangePhoneNumber({
        target: { name: 'phoneNumber', value: defaultValue },
        type: 'text',
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, []);

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation && resetValidation();
    const value = e.target.value.replace(/[^\d.]/g, '');
    const parsedPhoneNumber = phoneConverter.parsePhoneNumber(`+${value}`);
    let newValue = '';
    if (parsedPhoneNumber.phoneCode && parsedPhoneNumber.phoneNumber) {
      newValue = `${parsedPhoneNumber.phoneCode} ${parsedPhoneNumber.phoneNumber}`;
    } else {
      newValue = value;
    }
    setPhoneNumber(newValue);
    controls.onChange({ target: { name: 'phoneNumber', value: `+${value}` }, type: 'text' });
    onChangeValue && onChangeValue();
  };

  const getPlusAndronment = () => {
    return (
      <InputAdornment position="start">
        <div className={classes.plus}>+</div>
      </InputAdornment>
    );
  };

  return (
    <FormControl className={classes.control} error={error} hiddenLabel>
      <div className={classes.input}>
        <TextField
          autoComplete={'off'}
          className={classes.phoneNumber}
          autoFocus={autoFocus}
          onChange={onChangePhoneNumber}
          variant={'outlined'}
          type={'tel'}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            startAdornment: getPlusAndronment(),
          }}
          inputRef={phoneNumberInputRef}
          multiline={false}
          maxRows={maxRows || 0}
          value={phoneNumber}
        />
      </div>
      {helperText && helperText !== 'empty' && (
        <StyledFormHelperText>{helperText}</StyledFormHelperText>
      )}
    </FormControl>
  );
};

export const InputPhone = appWithStyles(styles)(InputPhoneComponent);
