import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormHelperText, TextField } from '@mui/material';

export const styles = () =>
  appCreateStyles({
    root: {
      width: '100%',
    },
    password: {
      letterSpacing: '7px',
      '& input': {
        fontFamily: 'monospace',
      },
    },
  });

export const StyledTextField = appWithStyles(() => ({
  root: {
    borderRadius: '12px',
    background: '#1E1D36',
    height: '52px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.error': {
      backgroundColor: '#231010',
    },
    '&.error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B42626 !important',
    },
  },
  focused: {},
}))(TextField);

export const StyledFormHelperText = appWithStyles(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '165%',
    letterSpacing: '0.2px',
    marginLeft: '0px',
    marginRight: '0px',
    top: '52px',
  },
}))(FormHelperText);
