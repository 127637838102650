import React from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Modal } from '@mui/material';
import { PersonaWidget } from '@shared/components/new-design/persona-widget';
import { DI_TOKENS } from '@shared/constants/di';
import { INotificationService } from '@shared/types/notification-service';

import { styles } from './kyc-dialog.styles';

export interface KYCDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 699,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const KYCDialogComponent: React.FC<KYCDialogProps> = ({ classes, onClose, isOpen }) => {
  const notificationService = appInject<INotificationService>(DI_TOKENS.notificationService);

  const onCompleteKYC = () => {
    notificationService.showSuccess(t`You are successfully Verified`);
    onClose();
  };

  const onErrorKYC = () => {
    notificationService.showSuccess(t`Your verification was Declined`);
    onClose();
  };

  const widget = <PersonaWidget onComplete={onCompleteKYC} onError={onErrorKYC} />;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="drawer-dialog-label"
      aria-describedby="drawer-dialog-description"
    >
      <Box sx={style}>
        <div className={classes.root}>
          <div className={classes.title}>{t`KYC`}</div>
          <div className={classes.widgetContentBlock}>
            <div className={classes.content}>{widget}</div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const KYCDialogDialog = appWithStyles(styles)(KYCDialogComponent);
