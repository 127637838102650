import React, { useEffect, useState } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { FormControl, InputAdornment, TextFieldProps } from '@mui/material';
import {
  EyeButton,
  EyeClosedButton,
} from '@shared/components/new-design/input-text/components/eye-button';
import { RemoveButton } from '@shared/components/new-design/input-text/components/remove-button/remove-button';
import { amountPipe } from '@shared/pipes';
import { sanitizedAmount } from '@shared/utils/metals';
import classNames from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';

import { StyledFormHelperText, StyledTextField, styles } from './input-text.styles';

export type InputTextFieldProps = AppWithStyles<any> &
  TextFieldProps & {
    resetValidation?: () => void;
    isAmount?: boolean;
    children?: React.ReactNode;
    controls: UseFormRegisterReturn;
    defaultValue?: string;
  };

enum InputType {
  text = 'text',
  password = 'password',
}
const InputTextComponent: React.FC<InputTextFieldProps> = ({
  classes,
  controls,
  resetValidation,
  defaultValue,
  type,
  helperText,
  error,
  autoFocus,
  placeholder,
  multiline,
  maxRows,
  isAmount,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState(InputType.password);

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue);
      controls.onChange({
        target: {
          value: defaultValue,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, []);
  const handleShowPassword = () => {
    setPasswordType(showPassword ? InputType.password : InputType.text);
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation && resetValidation();
    if (isAmount) {
      e.target.value = e.target.value ? amountPipe(sanitizedAmount(e.target.value)) : '';
    }
    setCurrentValue(e.target.value);
    controls.onChange(e);
  };

  const isPassword = type === InputType.password;
  const _type = isPassword ? passwordType : type;

  const removeValue = () => {
    resetValidation && resetValidation();
    setCurrentValue('');
    controls.onChange({
      target: {
        value: '',
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const getAndronment = () => {
    switch (true) {
      case !isPassword && !!currentValue:
        return (
          <InputAdornment position="end">
            <RemoveButton onClick={removeValue} />
          </InputAdornment>
        );
      case isPassword:
        return (
          <InputAdornment
            position="end"
            style={{
              position: 'relative',
              top: '0px',
              right: '6px',
            }}
          >
            {showPassword ? (
              <EyeClosedButton onClick={handleShowPassword} />
            ) : (
              <EyeButton onClick={handleShowPassword} />
            )}
          </InputAdornment>
        );
      default:
        return null;
    }
  };

  return (
    <FormControl className={classes.control} error={error} hiddenLabel>
      <div className={classes.input}>
        <StyledTextField
          {...controls}
          className={classNames({
            [classes.password as string]: isPassword && !showPassword && Boolean(currentValue),
            ['error']: error,
          })}
          autoFocus={autoFocus}
          onChange={handleOnChange}
          variant={'outlined'}
          type={_type}
          multiline={multiline || false}
          maxRows={maxRows || 0}
          value={currentValue}
          InputProps={{
            endAdornment: getAndronment(),
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      </div>
      {helperText && helperText !== 'empty' && (
        <StyledFormHelperText>{helperText}</StyledFormHelperText>
      )}
    </FormControl>
  );
};

export const InputText = appWithStyles(styles)(InputTextComponent);
